import CookieBar from '../components/cookie-bar';

export default {
  init() {
    const cookieBar = new CookieBar();
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
  },

  modifyDOM() {
  },
};
