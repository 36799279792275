import 'es6-promise/auto';
import $ from 'jquery';
import Router from './util/router';
import common from './routes/common';

const routes = {
  common,
};

$(document).ready(() => new Router(routes).loadEvents());
