import $ from 'jquery';
import * as Cookies from 'es-cookie';

export default class CookieBar {
  constructor() {
    this.$cookieBar = $('.cookie-bar');

    if (this.getCookie() !== 'true') {
      this.$cookieBar.addClass('cookie-bar__active');
    }

    this.addEvents();
  }

  addEvents() {
    this.$cookieBar.on('click', 'button', () => {
      this.setCookie();
      this.$cookieBar.removeClass('cookie-bar__active');
    });
  }

  setCookie() {
    Cookies.set('cookie-accept', 'true', { expires: 30 });
  }

  getCookie() {
    return Cookies.get('cookie-accept');
  }

  unsetCookie() {
    Cookies.remove('cookie-accept');
  }
}
